


.Skill {
    color: white;
    font-size: small;
    border-radius: 13px;
    padding: 4px;
    margin: 1px;
    justify-content: center;
    flex-wrap: wrap
}

.red{
    background: #ff6ae0;
}

.green{
    background: #73AD21;
}

.blue{
    background: #5a93e8;
}

.blue2{
    background: #8650f3;
}

.SkillContainer {
    flex-direction: row;
    display: flex;
    width: 500px;
    flex-wrap: wrap;
}
