@media all and (min-width: 480px) {



    .field {
        float: right;
        width: 170px;
        height: 23px;
        font: normal 13px Arial, Helvetica, sans-serif;
        padding: 6px 24px 6px 6px;
        margin: 25px 10px
    }

    input {
        text-shadow: none !important;
        background-color: #0c2a32;
        color: #0c2a32;
    }

    input:invalid {
        background-color: #FFAAAA;
    }

    input:valid {
        background-color: #AAFFAA;
    }

    .Login {
        padding: 60px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}